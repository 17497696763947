import React from "react"
import { graphql } from "gatsby"
import { orderBy, uniq, uniqBy } from "lodash"
import Container from "../components/Container"
import Section from "../components/Section"
import Site from "../components/Site"
import SiteLink from "../components/SiteLink"
import TalksFeatured from "../components/TalksFeatured"
import TalksRecent from "../components/TalksRecent"
import { formatLink, slugify } from '../helpers'
import "../css/talks.scss"

const TalksPage = ({data}) => {
  const allTalks        = [...data.allDatoCmsTalk.edges].map(el => el.node)
  const latestSermon    = [...allTalks].find(theTalk => theTalk.presentation.slug === 'sermons')
  const allSermons      = [...allTalks].filter(theTalk => theTalk.presentation.slug === 'sermons')
  const allStudentTalks = [...allTalks].filter(theTalk => theTalk.presentation.slug === 'student-talks')

  return (
    <Site
      title      = "Talks"
      className  = "hcc-talks hcc-talks-index"
      themeColor = "#141414"
    >
      <article>
        <Section className="hcc-talks-featured-section">
          <TalksFeatured data={latestSermon} single={false}/>
        </Section>
        <TalksRecent data={allSermons} title="Recent Sermons" offset={1} count={10} more={formatLink('sermons', 'p')}/>
        <TalksRecent data={allStudentTalks} title="Recent Student Talks" offset={0} count={10} more={formatLink('student-talks', 'p')}/>
        <TalksFilters data={allTalks}/>
      </article>
    </Site>
  )
}

export default TalksPage


// Filters
// -------

const TalksFilters = ({data}) => {
  const allSeries               = uniqBy([...data].map((theTalk, i, arr) => {return arr[i] = theTalk.series}), 'slug')
  const allSeriesFirstLetters   = orderBy(uniq(allSeries.map(theSeries => {return theSeries.slug.charAt(0).toUpperCase()})))
  const allSpeakers             = uniqBy([...data].map((theTalk, i, arr) => {return arr[i] = theTalk.speaker}), 'slug')
  const allSpeakersFirstLetters = orderBy(uniq(allSpeakers.map(theSpeaker => {return theSpeaker.slug.charAt(0).toUpperCase()})))
  const abc                     = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

  return (
    <Section className="hcc-talks-filters" data-datocms-noindex>
      <Container>
        <div className="hcc-talks-filters-content hcc-grid">
          <FilterTalks group="Series" abbv="se" data={allSeries} dataLetters={allSeriesFirstLetters} abc={abc}/>
          <FilterTalks group="Speaker" abbv="sp" data={allSpeakers} dataLetters={allSpeakersFirstLetters} abc={abc}/>
        </div>
      </Container>
    </Section>
  )
}

const FilterTalks = ({group, abbv, data, dataLetters, abc}) => {
  return (
    <div className={`hcc-talks-filters-${slugify(group)}`}>
      <h2 className="hcc-talks-content-title">Find a {group}</h2>
      <ul className="hcc-talks-filters-abc hcc-grid">
        {abc.map(theLetter => {
          return <li key={`filter-${slugify(group)}-by-${theLetter}`}>
                   {dataLetters.includes(theLetter)
                     ? <a href={`#${slugify(group)}-${theLetter.toLowerCase()}`}>{theLetter}</a>
                     : <span>{theLetter}</span>
                   }
                 </li>
        })}
      </ul>
      <ul className="hcc-talks-filters-list">
        {dataLetters.map(theLetter => {
          return <li key={`${theLetter}-${slugify(group)}-filter-content`} id={`${slugify(group)}-${theLetter.toLowerCase()}`}>
                   <strong>{theLetter}</strong>
                   <ul>
                     {data.map(theType => {
                       if ( theLetter === theType.slug.charAt(0).toUpperCase() ) {
                         return <li key={`${slugify(group)}-${theType.slug}`}><SiteLink href={formatLink(theType.slug, abbv)}>{abbv === 'se' ? theType.title : theType.name}</SiteLink></li>
                       } else {
                         return false
                       }
                     })}
                   </ul>
                 </li>
        })}
      </ul>
    </div>
  )
}


// GraphQL
// -------

export const query = graphql`
  query TalkQueryIndex {
    allDatoCmsTalk(
      sort : {fields : date, order : DESC}
    ) {
      edges {
        node {
          ...TalkFragment
        }
      }
    }
  }
`
